<template>
    <div class="Page home">
        <banner></banner>
        <stories class="hidden-xs-only"></stories>
        <storiesMobile class="hidden-sm-and-up"></storiesMobile>
        <saas></saas>
        <custom :title="customI18n.title" :list="customI18n.list"></custom>
        <reliable></reliable>
    </div>
</template>

<script>
import banner from './components/banner.vue';
import saas from './components/saas.vue';
import custom from './components/custom.vue';
import reliable from './components/reliable.vue';
import stories from './components/stories.vue';
import storiesMobile from './components/stories-mobile.vue';
export default {
    name: 'home',
    components: {
        banner,
        saas,
        custom,
        reliable,
        stories,
        storiesMobile,
    },
    data() {
        return {
            pageDurationID: 'Home',
        };
    },
    computed: {
        customI18n() {
            return this.$t('homePage.custom');
        },
    },
    mounted() {
        this.$track('home_imp');
    },
};
</script>

<style lang="scss">
.Page.home {
    background: #fff;
}
</style>
