<template>
    <div class="home-banner" :class="[currentLang]">
        <div class="content">
            <div :class="['machine', { 'machine-overseas': buildForOverseas }]"></div>
            <div class="info">
                <h1 class="poppins title" v-html="i18nPage.title"></h1>
                <div class="list">
                    <div class="item" v-for="(item, index) in i18nPage.list" :key="index">{{ item }}</div>
                </div>
                <div class="buttons">
                    <a href="javascript:;" class="ui-button button" @click="onDiscover">{{ $t('moreDetail') }}</a>
                    <a href="javascript:;" class="ui-button2 button" @click="onContact">{{ $t('contact') }}</a>
                </div>
            </div>
            <div class="model hidden-xs-only">
                <div class="text text1">{{ i18nPage.text1 }}</div>
                <div class="text text2">{{ i18nPage.text2 }}</div>
                <div class="text text3">{{ i18nPage.text3 }}</div>
            </div>
            <div class="imgs hidden-sm-and-up">
                <img :src="require(`@assets/images/home/banner/evelabinsight/m5_${currentLang}.png`)" alt="" v-if="buildForOverseas && ['en', 'ja', 'ko'].includes(currentLang)" />
                <img :src="require(`@assets/images/home/banner/m5_${currentLang}.png`)" alt="" v-else />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
        i18nPage() {
            return this.$t('homePage.banner');
        },
    },
    data() {
        return {
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },
    methods: {
        onContact() {
            this.$track('home_button_clk', {
                button: 'contactus',
            });
            this.mxShowContactModal({
                source: 'Home',
            });
        },
        onDiscover() {
            this.$track('home_button_clk', {
                button: 'discovermore',
            });
            this.$router.push({
                path: '/' + this.currentLang + '/product/evev',
                query: {
                    ...this.$router.query,
                },
            });
        },
    },
};
</script>

<style lang="scss">
.home-banner {
    @media only screen and (min-width: 769px) {
        position: relative;
        background: url(~@assets/images/home/banner/5.jpg) center no-repeat;
        background-size: cover;

        .content {
            max-width: 1108px;
            margin: 0 auto;
            height: 609px;
            position: relative;
            z-index: 2;
            padding: 0 20px;
        }
        .content {
            display: flex;
            position: relative;
            .machine {
                width: 554px;
                height: 205px;
                background: url(~@assets/images/home/banner/5-machine.png) 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                bottom: 0;
                left: -290px;
                &.machine-overseas {
                    background: url(~@assets/images/home/banner/evelabinsight/5-machine.png) 0 0 no-repeat;
                    background-size: contain;
                }
            }
        }
        .model {
            width: 580px;
            height: 609px;
            background: url(~@assets/images/home/banner/5-model.png) 0 0 no-repeat;
            background-size: contain;
            flex-shrink: 0;
            margin-top: auto;
            transform: translateX(-30px);
            position: relative;
            .text {
                position: absolute;
                width: 126px;
                right: 72px;
                text-align: center;
                font-size: 14px;
                font-weight: 300;
                &1 {
                    top: 156px;
                }
                &2 {
                    top: 268px;
                }
                &3 {
                    top: 382px;
                }
            }
        }
        .info {
            width: 565px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title {
                font-size: 40px;
                line-height: 45.6px;
                font-weight: 500;
                margin-bottom: 15px;
            }
            .list {
                .item {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                    margin-bottom: 4px;
                    position: relative;
                    padding-left: 13px;

                    &:before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        border-radius: 6px;
                        background: #000;
                        left: 0;
                        top: 7px;
                        position: absolute;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
            .desc {
                width: 464px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
            }
        }

        .buttons {
            margin-top: 35px;
            margin-bottom: 100px;
            transform: translateZ(0);
            display: flex;

            .button {
                &:last-of-type {
                    margin-left: 40px;
                    backdrop-filter: blur(5px);
                }
            }
        }
        &.zh,
        &.tw {
            .info {
                // width: 444px;

                .title {
                    font-size: 36px;
                    line-height: 50px;
                    margin-bottom: 10px;
                }
                .list {
                    .item {
                        font-size: 16px;
                        line-height: 25px;
                        margin-bottom: 0;
                        &:before {
                            top: 10px;
                        }
                    }
                }
                .desc {
                    line-height: 25px;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        box-sizing: border-box;
        position: relative;
        background: linear-gradient(180deg, #CDE1F6 0%, #D4E6F8 63.38%);
        .content {
            display: flex;
            flex-direction: column;
            height: 100%;

            .imgs {
                flex: 1;
                display: flex;
                align-items: center;

                img {
                    display: block;
                    width: 3.75rem;
                }
            }
        }
        .info {
            flex-shrink: 0;
            padding: 0.5rem 0.24rem 0 0.24rem;
        }
        .title {
            font-size: 0.26rem;
            line-height: 0.3rem;
            font-weight: 500;
        }
        .list {
            margin-top: 0.1rem;
            .item {
                font-size: 0.14rem;
                line-height: 0.18rem;
                font-weight: 400;
                margin-bottom: 0.03rem;
                position: relative;
                padding-left: 0.1rem;

                &:before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 4px;
                    background: #000;
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
        .desc {
            margin-top: 0.1rem;
            font-size: 0.16rem;
            line-height: 0.2rem;
            font-weight: 400;
        }
        .buttons {
            margin-top: 0.28rem;
            .button {
                &:last-of-type {
                    margin-left: 0.15rem;
                }
            }
        }
        .imgs {
            img {
                height: 3.12rem;
            }
        }
        &.zh,
        &.tw {
            .info {
                .title {
                    line-height: 0.36rem;
                }
                .list {
                    .item {
                        line-height: 0.2rem;
                    }
                }
                .desc {
                    font-size: 0.14rem;
                }
            }
            .buttons {
                .button {
                    &:last-of-type {
                        margin-left: 0.26rem;
                    }
                }
            }
        }
    }
}
</style>
