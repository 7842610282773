<template>
    <header id="page-header" class="g-header" :class="{ open: isMenuOpen, dark: isEveV }">
        <div class="g-header-border">
            <div class="g-header-container">
                <div class="g-header-container-left">
                    <a href="javascript:;" class="g-header-menu-icon hidden-sm-and-up" :class="{ close: isMenuOpen }" @click="menuToggle">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                    <img :src="pcLogo" alt="" class="g-header-logo g-header-logo-pc hidden-xs-only" @click="toHome" />
                    <!-- <div class="g-header-logo g-header-logo-pc hidden-xs-only" @click="toHome"></div> -->
                    <div class="g-header-logo g-header-logo-mobile hidden-sm-and-up" :class="{ show: isMenuOpen }" @click="toHome"></div>
                </div>
                <ul class="g-header-menu">
                    <li class="g-header-menu__item" @touchstart="onMenuover(menu.hasChild ? menu.key : '', true, menu.defaultNav)" @mouseover="onMenuover(menu.hasChild ? menu.key : '', true, menu.defaultNav)" @mouseleave="onMenuleave" :class="{ hassub: menu.child, open: isSubMenuOpen[menu.key] }" v-for="(menu, index) in menus" :key="index">
                        <div class="g-header-menu__item-handle">
                            <router-link :to="{ path: '/' + $route.params.lang + menu.route, query: $route.query }" class="g-header-menu__link" :class="{ isactive: $route.path === '/' && menu.route === '/home' }" active-class="active" @click.native="tabTrack(menu)">
                                {{ menu.text }}
                            </router-link>
                            <a v-if="menu.hasChild" class="g-header-submenu-icon" :class="{ close: isSubMenuOpen[menu.key] }" href="javascript:;" @click="isSubMenuOpen[menu.key] = !isSubMenuOpen[menu.key]">
                                <span></span>
                                <span></span>
                            </a>
                        </div>
                        <template v-if="menu.key === 'product'">
                            <div class="hidden-sm-and-up g-header-submenu" :class="{ open: isSubMenuOpen[menu.key] }">
                                <ul>
                                    <template v-for="product in productMenu">
                                        <li class="g-header-submenu__item" v-for="(subProduct, subProductIndex) in product.child" :key="product.key + subProductIndex" @click="tabTrack(subProduct)">
                                            <router-link :to="{ path: '/' + $route.params.lang + subProduct.route, query: $route.query }" class="g-header-menu__link" active-class="active">
                                                {{ subProduct.text }}
                                            </router-link>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </template>
                        <template v-if="otherMenus[menu.key]">
                            <div class="hidden-sm-and-up g-header-submenu" :class="{ open: isSubMenuOpen[menu.key] }">
                                <ul>
                                    <li class="g-header-submenu__item" v-for="(subMenu, subMenuIndex) in otherMenus[menu.key]" :key="otherMenus[menu.key] + subMenuIndex" @click="tabTrack(subMenu)">
                                        <router-link :to="{ path: '/' + $route.params.lang + subMenu.route, query: $route.query }" class="g-header-menu__link" active-class="active">
                                            {{ subMenu.text }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </li>
                    <li class="g-header-menu__item bp-login">
                        <div class="g-header-menu__item-handle">
                            <a class="g-header-login" :href="adminLink" target="_blank" @click="onMerchantLink">{{ $t('merchant_link') }}</a>
                        </div>
                    </li>
                    <li class="g-header-menu__item g-header-lang-select hidden-xs-only">
                        <div class="g-header-lang-select__current">{{ currentLangName }}</div>
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="7" height="4" viewBox="0 0 7 4">
                            <path fill="#000" fill-rule="nonzero" d="M3.5 4L0 0h7z" opacity=".7" />
                        </svg>

                        <select v-model="curLang">
                            <option v-for="(item, index) in currentLangList" :key="index" :value="item.id">{{ item.name }}</option>
                        </select>
                    </li>
                    <li class="g-header-menu__item g-header-lang-select hidden-sm-and-up">
                        <div class="g-header-menu__item-handle">
                            <span class="text">{{ $t('language') }}</span>
                            <div class="g-header-lang-select__list">
                                <a class="item" :class="{ active: currentLang === item.id }" href="javascript:;" v-for="(item, index) in currentLangList" :key="index" @click="langChange($event, item.id)">{{ item.name }}</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hidden-xs-only g-header-submenu" :class="{ show: subMenu === 'product' }" @mouseover="onMenuover('product')" @mouseleave="onMenuleave">
            <div class="poppins g-header-submenu-nav product">
                <div class="g-header-submenu-nav-item g-header-submenu__link" @click="onSubMenuNavClick(product)" :class="{ active: subMenuNav === product.key }" v-for="(product, productIndex) in productMenu" :key="productIndex">{{ product.text }}</div>
            </div>
            <div class="g-header-submenu__list">
                <ul class="poppins g-header-submenu__group" :class="[{ show: subMenuNav === product.key && !!subMenu }, product.key]" v-for="product in productMenu" :key="product.key">
                    <li class="g-header-submenu__item" v-for="(subProduct, subProductIndex) in product.child" :key="product.key + subProductIndex" @click="onSubMenuClick(subProduct)">
                        <router-link :to="{ path: '/' + $route.params.lang + subProduct.route, query: $route.query }" class="g-header-submenu__link" active-class="active">
                            <div class="img">
                                <img :src="require(`@assets/images/header/evelabinsight/${subProduct.key}.png`)" alt="" v-if="buildForOverseas && (subProduct.key == 'meitukey' || subProduct.key == 'eve' || subProduct.key == 'evev')" />
                                <img :src="require(`@assets/images/header/${subProduct.key}.png`)" alt="" v-else />
                            </div>
                            {{ subProduct.text }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hidden-xs-only g-header-submenu" :class="{ show: subMenu === key }" @mouseover="onMenuover(key)" @mouseleave="onMenuleave" v-for="(data, key) in otherMenus" :key="key">
            <div class="poppins g-header-submenu-nav">
                <div class="g-header-submenu-nav-item" v-for="item in data" :key="item.key" @click="onSubMenuClick(item)">
                    <router-link :to="{ path: '/' + $route.params.lang + item.route, query: $route.query }" class="g-header-submenu__link" active-class="active">
                        {{ item.text }}
                    </router-link>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import iNoBounce from '../../utils/inobounce';

export default {
    data() {
        return {
            isMenuOpen: false,
            isSubMenuOpen: {
                product: false,
                faq: false,
                aboutus: false,
            },
            subMenu: '',
            subMenuNav: '',
            isEveV: this.$route.name === 'productEveV',
            buildForOverseas: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
        };
    },

    methods: {
        ...mapMutations({
            set_lang: 'SET_LANG',
        }),
        onSubMenuClick(data) {
            this.subMenu = '';
            this.tabTrack(data);
        },
        onMenuover(key, isFindSubMenuNav, defaultNav) {
            if (key) {
                this.subMenu = key;

                if (isFindSubMenuNav && this.$route && this.$route.meta && this.$route.meta.subMenuNav) {
                    this.subMenuNav = this.$route.meta.subMenuNav;
                } else if (defaultNav) {
                    this.subMenuNav = defaultNav;
                }
            }
        },
        onMenuleave() {
            this.subMenu = '';
        },
        onSubMenuNavClick(menu) {
            this.subMenuNav = menu.key;
        },
        tabTrack(data = {}) {
            if (data.track) {
                this.subMenu = '';
                this.$track('tab_clk', {
                    tab: data.track,
                });
            }
        },
        langChange(e, id) {
            if (id) {
                this.$track('language_switch_clk', {
                    type: id,
                });
            }
            // 国内域名 点击 英日韩 跳转海外域名
            if (process.env.VUE_APP_BUILD_FOR === 'evelabinsight') {
                let eveMap = {
                    zh: process.env.VUE_APP_EVE_ZH_HOST,
                    tw: process.env.VUE_APP_EVE_TW_HOST,
                };
                if (eveMap[id]) {
                    location.href = eveMap[id];
                    return;
                }
            } else {
                var labinsightMap = {
                    en: process.env.VUE_APP_EVE_EN_HOST,
                    ja: process.env.VUE_APP_EVE_JA_HOST,
                    ko: process.env.VUE_APP_EVE_KO_HOST,
                };
                if (labinsightMap[id]) {
                    location.href = labinsightMap[id];
                    return;
                }
            }
            if (!id) {
                id = this.curLang;
            }
            this.$i18n.locale = id;
            this.set_lang(id);
            let opts = {
                params: {
                    lang: id,
                },
            };
            // 英文用 webflow
            if (id === 'en') {
                location.href = process.env.VUE_APP_HOST;
                return;
            }
            if (this.$route.path === '/') {
                opts.path = `/${id}/home`;
            }
            this.$router.push(opts);
        },
        getLangNameById(id) {
            let data;
            this.langList.forEach(item => {
                if (item.id === id) {
                    data = item.name;
                }
            });
            return data;
        },
        menuToggle() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        route(path, query = {}) {
            this.$router.push({
                path: `/${this.currentLang}/${path}`,
                query: {
                    ...this.$route.query,
                    ...query,
                },
            });
        },
        toHome() {
            this.$track('tab_click', {
                type: 'logo',
            });
            this.route('');
            window.scrollTo(0, 0);
        },
        onMerchantLink() {
            this.$track('login_clk');
        },
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
            langList: state => state.langList,
        }),
        currentLangList() {
            if (process.env.VUE_APP_BUILD_FOR === 'evelabinsight') {
                var arr = ['en', 'ko', 'ja'];
                return this.langList.filter(item => {
                    return arr.indexOf(item.id) >= 0;
                });
            } else {
                return this.langList;
            }
        },
        pcLogo() {
            let { currentLang, isEveV } = this;
            if (currentLang === 'zh') {
                if (isEveV) {
                    return require(`@assets/svg/zh/logo_white.svg`);
                } else {
                    return require(`@assets/svg/zh/logo_black.svg`);
                }
            } else if (currentLang === 'tw') {
                if (isEveV) {
                    return require(`@assets/svg/tw/logo_white.svg`);
                } else {
                    return require(`@assets/svg/tw/logo_black.svg`);
                }
            } else {
                if (isEveV) {
                    return require(`@assets/svg/en/logo_white.svg`);
                } else {
                    return require(`@assets/svg/en/logo_black.svg`);
                }
            }
        },
        isAbout() {
            return this.$route.path.indexOf('/about/') > 0;
        },
        isContact() {
            return this.$route.path.indexOf('/contact') > 0;
        },
        isParam() {
            return this.$route.path.indexOf('/param') > 0;
        },
        isProduct() {
            return this.$route.path.indexOf('/product') > 0;
        },
        i18nCommon() {
            return this.$t('common');
        },
        curLang: {
            set(val) {
                this.langChange('', val);
            },
            get() {
                return this.currentLang;
            },
        },
        currentLangName() {
            return this.getLangNameById(this.currentLang);
        },
        productMenu() {
            let { currentLang } = this;
            return [
                {
                    key: 'skin',
                    text: this.$t('skinAnalysis'),
                    child: [
                        {
                            key: 'evev',
                            route: '/product/evev',
                            text: this.$t('eveV'),
                            track: 'MeituEveV',
                        },
                        {
                            key: 'eve',
                            route: '/product/eve',
                            text: this.$t('eve'),
                            track: 'MeituEveM',
                        },
                        {
                            key: currentLang === 'zh' ? 'skinsdk' : 'skinsdk_en',
                            route: '/product/skin-mirror',
                            text: this.$t('mirror'),
                            track: 'MeituEveSkin',
                        },
                        {
                            key: 'meitukey',
                            route: '/product/evekey',
                            text: this.$t('meitukey'),
                            track: 'MeituKey',
                        },
                    ],
                },
                {
                    key: 'makeup',
                    text: this.$t('arVirtual'),
                    child: [
                        {
                            key: 'virtualMakeup',
                            route: '/product/virtual-makeup',
                            text: this.$t('virtualMakeup'),
                            track: 'Makeup',
                        },
                        {
                            key: 'virtualHair',
                            route: '/product/virtual-hair-dye',
                            text: this.$t('virtualHair'),
                            track: 'HairDye',
                        },
                        {
                            key: 'accessories',
                            route: '/product/accessories',
                            text: this.$t('accessories'),
                            track: 'Accessories',
                        },
                        {
                            key: 'contactLenses',
                            route: '/product/contact-lenses',
                            text: this.$t('contactLenses'),
                            track: 'Lenses',
                        },
                    ],
                },
            ];
        },
        otherMenus() {
            return {
                faq: [
                    {
                        key: 'faqInfo',
                        route: '/qa/info',
                        text: this.$t('faq'),
                        track: 'FAQs',
                    },
                    {
                        key: 'techSpecs',
                        route: '/qa/tech-specs/eveV',
                        text: this.$t('techSpecs'),
                        track: 'TechSpecs',
                    },
                    {
                        key: 'downloadCenter',
                        route: '/qa/download',
                        text: this.$t('downloadCenter'),
                        track: 'DownloadCenter',
                    },
                ],
                // aboutus: [
                //     {
                //         key: 'aboutusSolution',
                //         route: '/aboutus/info#solution',
                //         text: this.$t('aboutusSolution'),
                //         track: 'Aboutus_solutions',
                //     },
                //     {
                //         key: 'aboutusTech',
                //         route: '/aboutus/info#tech',
                //         text: this.$t('aboutusTech'),
                //         track: 'Aboutus_Technology',
                //     },
                //     {
                //         key: 'aboutusCompany',
                //         route: '/aboutus/info#company',
                //         text: this.$t('aboutusCompany'),
                //         track: 'Aboutus_introduction',
                //     },
                //     {
                //         key: 'aboutusNews',
                //         route: '/news',
                //         text: this.$t('news'),
                //         track: 'Aboutus_News',
                //     },
                // ],
            };
        },
        menus() {
            return [
                {
                    route: '/home',
                    text: this.$t('home'),
                    track: 'Home',
                },
                {
                    key: 'product',
                    route: '/product',
                    text: this.$t('product'),
                    hasChild: true,
                    track: 'Product',
                    defaultNav: 'skin',
                },
                {
                    route: '/solutions',
                    text: this.$t('solutions'),
                    track: 'Solution',
                },
                {
                    route: '/success-case',
                    text: this.$t('successCase'),
                    track: 'Successcase',
                },
                {
                    key: 'faq',
                    route: '/qa',
                    hasChild: true,
                    text: this.$t('qa'),
                    track: 'FAQs',
                },
                {
                    key: 'aboutus',
                    route: '/aboutus',
                    text: this.$t('aboutUs'),
                    track: 'Aboutus',
                },
                {
                    route: '/contact',
                    text: this.$t('contact'),
                    track: 'Contactus',
                },
            ];
        },
        adminLink() {
            let link = process.env.VUE_APP_BP_HOST;
            link += `?lang=${this.currentLang}#/auth/login`;
            return link;
        },
    },
    mounted() {
        console.log('~~~~~~~=', process.env.VUE_APP_BP_HOST);
        // var controller = new ScrollMagic.Controller();

        iNoBounce.disable();
        // new ScrollMagic.Scene({
        //     offset: 80,
        // })
        //     .on('enter', () => {
        //         console.log('!!!!!!!!!!!!!!!');
        //         this.isHeaderFixed = true;
        //     })
        //     .on('leave', () => {
        //         this.isHeaderFixed = false;
        //     })
        //     .setClassToggle('.g-header', 'fixed')
        //     .addTo(controller);

        // new ScrollMagic.Scene({
        //     offset: 100,
        // })
        //     .setClassToggle('.g-header-logo-mobile', 'show')
        //     .addTo(controller);
    },
    watch: {
        '$route.name'(val) {
            this.isEveV = val === 'productEveV';
        },
        '$route.fullPath'() {
            this.isMenuOpen = false;
        },
        isMenuOpen(val) {
            this.$nextTick(() => {
                if (typeof document !== 'object') return;
                var ua = navigator.userAgent.toLowerCase();
                var isAndroid = /android|adr|miui|samsung|huawei|harmony/gi.test(ua);
                if (!isAndroid) {
                    if (val) {
                        iNoBounce.enable();
                    } else {
                        iNoBounce.disable();
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
.g-header {
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    box-sizing: border-box;
    transition: all 0.5s;

    &-menu {
        &__item {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 1);
            flex-shrink: 0;
            position: relative;

            &-handle {
                display: flex;
                align-items: center;
                height: 100%;
                box-sizing: border-box;
            }

            &.hassub {
                display: block;
                overflow: hidden;
                transition: all 0.6s;

                &.open {
                    height: auto;
                }
            }

            &.active,
            &.isactive {
                color: #000;
                font-weight: 400;
                text-decoration: none;
            }
        }
        &__link {
            text-decoration: none;
            font-weight: 400;
            color: rgba(0, 0, 0, 1);
            white-space: nowrap;

            &:hover {
                color: rgba(0, 0, 0, 0.6);
            }

            &.active,
            &.isactive {
                color: #000;
                font-weight: 400;
            }
        }

        &-child {
            display: none;
        }
    }
}
@media only screen and (min-width: 769px) {
    .g-header {
        background: #fff;

        &.dark {
            background: rgba(10, 10, 12, 0.5);
            backdrop-filter: blur(100px);

            .g-header-border {
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            }

            .g-header-menu__link,
            .g-header-menu__link.active,
            .g-header-menu__link.isactive,
            .g-header-menu__item,
            .g-header-login {
                color: #fff;
            }
            .g-header-menu__link:after {
                background: #fff;
            }
            .g-header-lang-select .icon path {
                fill: #fff;
            }
        }

        &-border {
            border-bottom: 1px solid rgba(233, 233, 233, 0.8);
        }

        &-container {
            height: 57px;
            max-width: 1108px;
            display: flex;
            align-items: center;
            margin: 0 auto;
        }

        &-logo {
            display: block;
            min-width: 126px;
            height: 29px;
            margin-right: 20px;
            cursor: pointer;
        }
        &-menu {
            flex: 1;
            display: flex;
            height: 100%;
            justify-content: space-between;
            align-items: center;

            .bp-login {
                margin-left: auto;
                margin-right: 40px;
            }

            &__link {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                margin: 0 20px;
                position: relative;

                &:after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #000;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    transform: scaleX(0);
                }

                &.active,
                &.isactive {
                    font-weight: bold;

                    &:after {
                        transition: all 0.3s;
                        transform: scaleX(1);
                    }
                }
            }

            &__item {
                height: 100%;
                display: flex;
                align-items: center;
                list-style: none;

                &:first-of-type {
                    margin-left: auto;
                }

                &:hover {
                    .g-header-menu-child {
                        display: block;
                    }
                }
            }
            &-child {
                position: absolute;
                top: 60px;
                left: 50%;
                transform: translateX(-50%);
                background: #fff;
                border: 1px solid #e8e8e8;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
                padding: 14px 25px;
                width: auto;

                .g-header-menu__link {
                    padding: 0 20px;
                    font-size: 16px;
                    line-height: 40px;
                    color: #000000;
                    text-align: center;

                    &.active {
                        opacity: 1;
                        color: #1fc8d1;
                    }
                }
            }
        }
        &-lang-select {
            // min-width: 60px;
            position: relative;

            &__current {
                padding: 5px 10px;
                text-align: right;
            }

            .icon {
                width: 10px;
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                right: 0;
            }

            select {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
                -webkit-appearance: menulist-button;
            }
        }
        &-login {
            margin-left: 47px;
            color: #000000;
        }
        &-submenu {
            overflow: hidden;
            max-height: 0;
            background: #fff;
            box-shadow: 0 20px 24px rgba(0, 0, 0, 0.1);
            transition: max-height 0.4s cubic-bezier(0, 1, 0, 1);

            &.show {
                max-height: 272px;
                transition: max-height 0.4s ease-in-out, box-shadow 4s;
            }

            &-nav {
                display: flex;
                align-items: center;
                justify-content: center;

                &.product {
                    .g-header-submenu-nav-item {
                        margin: 10px 70px 15px 70px;
                    }
                    .g-header-submenu__link {
                        padding: 10px 0;
                    }
                }

                &-item {
                    margin: 0 70px;
                    color: rgba(0, 0, 0, 0.6);
                    position: relative;
                    cursor: pointer;
                    transition: all 0.3s;

                    &.active {
                        color: #000;
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
            }

            &__list {
                height: 170px;
                position: relative;
            }
            &__group {
                left: 50%;
                transform: translateX(-50%);
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.4s;
                display: flex;
                justify-content: space-between;

                .g-header-submenu__item {
                    opacity: 0;
                    transition: all 0.5s;

                    @for $i from 1 through 5 {
                        &:nth-child(#{$i}) {
                            transition-delay: #{$i / 12 + 0.1}s !important;
                        }
                    }
                }
                .g-header-submenu__link {
                    color: #000;

                    &:after {
                        display: none;
                    }
                    &.active {
                        opacity: 0.6;
                        cursor: default;
                    }
                }

                &.show {
                    opacity: 1;
                    visibility: visible;

                    .g-header-submenu__item {
                        opacity: 1;
                        transition: all 1.5s;
                    }
                }

                &.skin {
                    width: 1000px;
                    .g-header-submenu__item {
                        width: 216px;
                        .img {
                            height: 100px;
                            margin-bottom: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img {
                                display: block;
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        &:nth-of-type(4) {
                            img {
                                height: 164px;
                            }
                        }
                    }
                }
                &.makeup {
                    width: 1180px;
                    max-width: 100%;
                    justify-content: space-between;
                    .g-header-submenu__item {
                        width: 240px;
                    }
                    .img {
                        margin-top: 20px;
                        margin-bottom: 16px;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            display: block;
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
            &__link {
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                padding: 14px 0;
                color: #999;
                display: flex;
                flex-direction: column;
                text-align: center;

                &:after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #00C8C8;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transform: scaleX(0);
                    transition: all 0.3s;
                }

                &.active {
                    color: #000;
                    img {
                        transition: none !important;
                        transform: none !important;
                    }
                    &:after {
                        transform: scaleX(1);
                    }
                }

                img {
                    transition: transform 0.4s;
                    transform-origin: center 100%;
                }
                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1108px) and (min-width: 769px) {
    .g-header {
        &-border {
            padding: 0 20px;
            box-sizing: border-box;
        }
    }
}
@media only screen and (max-width: 768px) {
    .g-header {
        background: #fff;
        opacity: 0.98;
        padding: 0;
        position: fixed;
        background: #00C8C8;
        height: 48px;
        overflow: hidden;

        &.dark {
            background: rgba(10, 10, 12, 0.5);
            backdrop-filter: blur(100px);
        }

        &-border {
            height: 100%;
        }

        &-logo {
            &-mobile {
                width: 23px;
                height: 25px;
                background: url('~@assets/svg/logo2.svg') 0 0 no-repeat;
                background-size: contain;
                margin: 0 auto;

                &.show {
                    opacity: 1;
                }
            }
        }

        &-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            &-left {
                width: 100%;
                height: 48px;
                display: flex;
                align-items: center;
            }
        }

        &-menu-icon {
            width: 48px;
            height: 48px;
            position: absolute;

            span {
                position: absolute;
                width: 18px;
                height: 1px;
                background: #fff;
                left: 16px;
                top: 24px;
                transition: all 0.3s ease-in-out;
                -webkit-transform: scaleY(1.5);
                -ms-transform: scaleY(1.5);
                transform: scaleY(1.5);

                &:first-child {
                    top: 17px;
                }

                &:last-child {
                    top: 31px;
                }
            }

            &.close {
                span:first-child {
                    transition: all 0.3s ease-in-out 0.2s;
                    transform: rotate(45deg) translate(5px, 5px);
                }

                span:nth-child(2) {
                    transition: all 0.5s ease-in-out;
                    opacity: 0;
                }

                span:nth-child(3) {
                    transition: all 0.3s ease-in-out 0.3s;
                    transform: rotate(-45deg) translate(5px, -5px);
                }
            }
        }

        &-menu {
            flex: 1;
            overflow-x: hidden;
            overflow-y: auto;
            width: 100%;
            padding: 0 30px;
            box-sizing: border-box;
            opacity: 0;
            visibility: hidden;

            &__item {
                white-space: nowrap;
                color: #fff;
                list-style: none;
                width: 100%;
                height: 50px;
                font-size: 14px;
                align-items: center;
                opacity: 0;
                transform: scale(1.2) translateY(-20%);
                transition: none;
                overflow: hidden;

                &-handle {
                    border-bottom: solid 1px rgba(255, 255, 255, 0.3);
                }
                &.open {
                    height: auto;
                }
            }
            &__link {
                display: flex;
                flex: 1;
                height: 50px;
                align-items: center;
                color: #fff;
                font-weight: 400;

                &.active,
                &.isactive {
                    color: #fff;
                    font-weight: 900;
                }
            }
        }

        &-submenu {
            margin-left: 24px;

            &-icon {
                width: 24px;
                height: 24px;
                margin-left: auto;
                margin-right: 6px;
                position: relative;

                span {
                    width: 14px;
                    height: 1px;
                    background: #fff;
                    position: absolute;
                    left: 5px;
                    top: 11px;

                    &:last-of-type {
                        transform: rotate(-90deg);
                        transition: all 0.4s;
                    }
                }

                &.close {
                    span {
                        &:last-of-type {
                            transform: rotate(0);
                        }
                    }
                }
            }

            &__item {
                opacity: 0;
                transform: translateY(-20%) scale(1.2);
                transition: none;
            }

            &.open {
                .g-header-submenu__item {
                    opacity: 1;
                    transform: translateY(0) scale(1);
                    transition: opacity 0.25s 0.1s ease-out, transform 0.25s 0.1s ease-out;

                    @for $i from 1 through 10 {
                        &:nth-child(#{$i}) {
                            transition-delay: #{$i / 10 + 0.08}s, #{$i / 10 + 0.1}s;
                        }
                    }
                }
            }
        }

        &-lang-select {
            height: auto;
            min-height: 50px;
            align-items: flex-start;

            &__list {
                margin-top: 10px;
                margin-bottom: 10px;
                margin-left: auto;
                white-space: normal;
                text-align: right;
                font-size: 0;

                .item {
                    color: #fff;
                    opacity: 0.8;
                    text-decoration: none;
                    margin-left: 16px;
                    font-size: 14px;
                    white-space: nowrap;
                    margin-top: 6px;
                    margin-bottom: 6px;
                    display: inline-block;

                    &.active {
                        opacity: 1;
                        font-weight: 600;
                    }
                }
            }
        }

        &-login {
            color: #fff;
        }

        &.open {
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 2000;
            align-items: flex-start;

            .g-header-menu {
                opacity: 1;
                visibility: visible;
            }

            .g-header-menu__item {
                opacity: 1;
                transform: none;
                transition: opacity 0.25s 0.1s ease-out, transform 0.25s 0.1s ease-out;

                @for $i from 1 through 20 {
                    &:nth-of-type(#{$i}) {
                        transition-delay: #{$i / 10 + 0.08}s, #{$i / 10 + 0.1}s;
                    }
                }
            }
        }
    }
    @at-root html.is-menu-open body {
        overflow: hidden;
    }
}
@media only screen and (min-width: 2300px) {
    .g-header {
        &-container {
            max-width: 1400px;
        }
    }
}
</style>
