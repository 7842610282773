<template>
    <footer id="footer" class="g-footer" :class="[currentLang]">
        <div class="g-footer-container">
            <img :src="logo" alt="" class="g-footer-logo" />
            <div class="g-footer-main">
                <div class="g-footer-list">
                    <div class="g-footer-item product" :class="{ open: isSubMenuOpen['product'] }">
                        <div class="g-footer-item-title" @click="isSubMenuOpen['product'] = !isSubMenuOpen['product']">
                            {{ $t('product') }}
                            <div class="g-footer-submenu-icon hidden-sm-and-up" :class="{ close: isSubMenuOpen['product'] }">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div class="g-footer-item-value">
                            <router-link v-for="(item, index) in productList" :key="index" :to="{ path: '/' + $route.params.lang + item.route, query: $route.query }" class="g-footer-item-value-item">
                                {{ item.name }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="g-footer-list">
                    <!-- <div class="g-footer-item support" :class="{ open: isSubMenuOpen['support'] }">
                        <div class="g-footer-item-title" @click="isSubMenuOpen['support'] = !isSubMenuOpen['support']">
                            {{ $t('support') }}
                            <div class="g-footer-submenu-icon hidden-sm-and-up" :class="{ close: isSubMenuOpen['support'] }">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div class="g-footer-item-value">
                            <router-link v-for="(item, index) in supportList" :key="index" :to="{ path: '/' + $route.params.lang + item.route, query: $route.query }" class="g-footer-item-value-item">
                                {{ item.name }}
                            </router-link>
                        </div>
                    </div> -->
                    <div class="g-footer-item contact">
                        <div class="g-footer-item-title">{{ $t('contact') }}</div>
                        <div class="g-footer-item-value">
                            <a href="mailto:mteve@meitu.com" class="g-footer-item-value-item">Email: {{ $t('emailAddress') }}</a>
                            <a href="javascript:;" class="g-footer-item-value-item" v-if="currentLang === 'zh'">售后支持：400-990-9696</a>
                        </div>
                    </div>
                    <div class="g-footer-item follow" v-if="followList && followList.length">
                        <div class="g-footer-item-title">{{ $t('followUs') }}</div>
                        <div class="g-footer-item-value g-footer-follow-list">
                            <a target="_black" :href="follow.link" class="g-footer-follow-item" v-for="follow in followList" :key="follow.name" :style="`background: url(${follow.icon}) 0 0 no-repeat;`"></a>
                        </div>
                    </div>
                </div>
                <div class="g-footer-list">
                    <!-- <div class="g-footer-item subscribe" id="subscribe">
                        <div class="g-footer-item-title">{{ $t('subscribe') }}</div>

                        <div class="g-footer-item-value">
                            <div class="g-footer-subscribe-title">{{ $t('subscribeTitle') }}</div>
                            <div class="g-footer-subscribe-form" :class="{ shake: isSubscribeError }">
                                <input type="text" name="email" v-model="email" placeholder="E-mail*" :data-vv-scope="scope" :data-vv-as="$t('email')" v-validate="`required|email|max:50`" data-vv-name="email" />
                                <div class="g-footer-subscribe-form-submit" @click="onSubmit">></div>
                                <span class="error" v-show="vee_errors.has(`${scope}.email`)">{{ $t('emailValidate') }}</span>
                            </div>
                            <div class="g-footer-subscribe-success" v-if="isShowSubscribeSuccess">{{ $t('subscribedSuccess') }}</div>

                            <div class="g-footer-subscribe-desc">{{ $t('subscribeDesc') }}</div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="g-footer-copyright">
                <div class="g-footer-copyright-item">{{ $t(isEvelabinsightCn ? 'copyrightCn' : 'copyright', [year]) }}</div>
                <div class="g-footer-copyright-item" v-if="!isEvelabinsight && !isEvelabinsightCn">{{ $t('copyright1') }}</div>
                <div class="g-footer-copyright-item" v-if="!isEvelabinsight && !isEvelabinsightCn">{{ $t('copyright2') }}</div>
                <div class="g-footer-copyright-item" v-if="!isEvelabinsight"><a href="https://beian.miit.gov.cn">{{ isEvelabinsightCn ? '闽ICP备2024079383号-1' : '闽ICP备 2022001418号' }}</a></div>
                <div class="g-footer-copyright-item" v-if="isEvelabinsightCn"><a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35021202000705">闽公网安备35021202000705号</a></div>
            </div>
        </div>
    </footer>
</template>

<script>
import qs from 'qs';
import http from '@utils/http';
import { mapState } from 'vuex';
import { getCookieItem } from '@utils/common';
export default {
    inject: ['$validator'],
    data() {
        return {
            isSubscribeError: false,
            email: '',
            isSubmiting: false,
            isShowSubscribeSuccess: false,
            scope: 'footerEmail',
            action: process.env.VUE_APP_EMAIL_API,
            isSubMenuOpen: {
                product: false,
                support: false,
            },
            year: new Date().getFullYear(),
            followList: process.env.VUE_APP_BUILD_FOR === 'evelabinsight' ? [
                {
                    name: 'youtube',
                    icon: require('@assets/svg/youtube.svg'),
                    link: 'https://www.youtube.com/@evelabinsight',
                },
                {
                    name: 'twitter',
                    icon: require('@assets/svg/twitter.svg'),
                    link: 'https://twitter.com/evelabinsight',
                },
                {
                    name: 'facebook',
                    icon: require('@assets/svg/facebook.svg'),
                    link: 'https://www.facebook.com/evelabinsight',
                },
                {
                    name: 'linkein',
                    icon: require('@assets/svg/linkein.svg'),
                    link: 'https://www.linkedin.com/company/evelab-insight/posts/?feedView=all&viewAsMember=true',
                },
            ] : [],
            isEvelabinsight: process.env.VUE_APP_BUILD_FOR === 'evelabinsight',
            isEvelabinsightCn: location.host.indexOf('evelabinsight.cn') >= 0,
        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang,
        }),
        logo() {
            let { currentLang } = this;
            if (currentLang === 'zh') {
                return require(`@assets/svg/zh/logo_white.svg`);
            } else if (currentLang === 'tw') {
                return require(`@assets/svg/tw/logo_white.svg`);
            } else {
                return require(`@assets/svg/en/logo_white.svg`);
            }
        },
        i18nPage() {
            return this.$t('footer');
        },
        productList() {
            return [
                {
                    route: '/product/eveV',
                    name: this.$t('eveV'),
                },
                {
                    route: '/product/eve',
                    name: this.$t('eve'),
                },
                {
                    route: '/product/skin-mirror',
                    name: this.$t('mirror'),
                },
                {
                    route: '/product/evekey',
                    name: this.$t('meitukey'),
                },
                {
                    route: '/product/virtual-makeup',
                    name: this.$t('virtualMakeup'),
                },
                {
                    route: '/product/virtual-hair-dye',
                    name: this.$t('virtualHair'),
                },
                {
                    route: '/product/accessories',
                    name: this.$t('accessories'),
                },
                {
                    route: '/product/contact-lenses',
                    name: this.$t('contactLenses'),
                },
            ];
        },
        supportList() {
            return [
                {
                    route: '/product/eve',
                    name: this.$t('qa'),
                },
                {
                    route: '/product/eve',
                    name: this.$t('downloadCenter'),
                },
            ];
        },
    },
    methods: {
        validateAll() {
            return this.$validator.validateAll(this.scope).then(value => {
                if (this.vee_errors.any()) {
                    return Promise.reject(this.vee_errors);
                }
                return Promise.resolve(value);
            });
        },
        onSubmit() {
            this.validateAll()
                .then(() => {
                    let api = `${process.env.VUE_APP_API_HOST}h5/proxy_post`;

                    if (this.isSubmiting) return;
                    this.isSubmiting = true;

                    let data = {
                        email: this.email,
                        url: process.env.VUE_APP_EMAIL_API,
                        page_url: location.href,
                        _ga: getCookieItem('_ga') || '',
                        _gid: getCookieItem('_gid') || '',
                    };

                    http.post(api, qs.stringify(data))
                        .then(() => {
                            this.$nextTick(() => {
                                this.isSubmiting = false;
                                this.email = '';
                                this.$nextTick(() => {
                                    setTimeout(() => {
                                        this.vee_errors.clear('footerEmail');
                                        this.isShowSubscribeSuccess = true;
                                        setTimeout(() => {
                                            this.isShowSubscribeSuccess = false;
                                        }, 5000);
                                    }, 10);
                                });
                            });
                        })
                        .catch(error => {
                            this.$emit('error');
                            console.error(error);
                            this.isSubmiting = false;
                        });
                })
                .catch(() => {
                    this.isSubscribeError = true;
                    setTimeout(() => {
                        this.isSubscribeError = false;
                    }, 500);
                });
        },
    },
};
</script>

<style lang="scss">
.g-footer {
    background: #4a4a4a;

    &-subscribe {
        &-form {
            &.shake {
                animation: shakeX 0.5s;

                @keyframes shakeX {
                    from,
                    to {
                        transform: translate3d(0, 0, 0);
                    }

                    15%,
                    50%,
                    85% {
                        transform: translate3d(-10px, 0, 0);
                    }

                    35%,
                    65% {
                        transform: translate3d(10px, 0, 0);
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 769px) {
        &-container {
            max-width: 1108px;
            margin: 0 auto;
            overflow: hidden;
            padding: 0 20px;
        }
        &-logo {
            height: 44px;
            margin: 48px 0;
        }
        &-main {
            display: flex;
            padding-bottom: 60px;
        }
        &-list {
            flex: 1;
            flex-shrink: 0;
        }
        &-item {
            &.support {
                margin-bottom: 34px;
            }
            &.contact {
                margin-bottom: 34px;
            }
            &-title {
                font-weight: 700;
                color: #ebebeb;
                margin-bottom: 17px;
                font-size: 20px;
                line-height: 1.5;
            }
            &-value {
                &-item {
                    display: block;
                    color: #ebebeb;
                    font-size: 13px;
                    font-weight: 400;
                    margin-bottom: 15px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &-follow-list {
            display: flex;
        }
        &-follow-item {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        &-subscribe {
            &-title {
                font-size: 13px;
                color: #ebebeb;
            }
            &-form {
                margin-top: 15px;
                display: flex;
                position: relative;
                input {
                    width: 194px;
                    height: 36px;
                    background: #ebebeb;
                    border: none;
                    border-radius: 0;
                    padding: 4px 10px;
                    box-sizing: border-box;
                }
                &-submit {
                    width: 36px;
                    height: 36px;
                    background: #5CDAD7;
                    margin-left: 10px;
                    font-size: 20px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Roboto;
                    font-weight: 700;
                    cursor: pointer;
                }
                .error {
                    white-space: nowrap;
                    font-size: 12px;
                    transform: scale(0.75);
                    color: #FE480F;
                    position: absolute;
                    transform-origin: 0 center;
                    left: 0;
                    top: 36px;
                }
            }
            &-success {
                font-size: 13px;
                font-weight: 600;
                margin-top: 6px;
                color: #5CDAD7;
            }
            &-desc {
                width: 250px;
                font-size: 10px;
                color: #ebebeb;
                margin-top: 15px;
            }
        }
        &-copyright {
            width: 1108px;
            height: 58px;
            padding-bottom: 12px;
            box-sizing: border-box;
            margin: 0 auto;
            border-top: solid 1px rgba(235, 235, 235, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 400;
            color: rgba(235, 235, 235, 0.4);

            &-item {
                margin-right: 24px;

                &:last-of-type {
                    margin-right: 0;
                }

                a {
                    color: rgba(235, 235, 235, 0.4);
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        &-container {
            overflow: hidden;
        }
        &-logo {
            height: 34px;
            margin: 70px 0 40px 30px;
        }
        &-main {
            overflow: hidden;
            padding: 0 30px 20px 30px;
        }
        &-item {
            &-title {
                width: 100%;
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 5px;
                color: #ebebeb;
                display: flex;
                align-items: center;
            }
            &.product,
            &.support {
                height: 50px;
                overflow: hidden;
                border-bottom: 1px solid rgba(235, 235, 235, 0.1);

                .g-footer-item-title {
                    height: 50px;
                    margin-bottom: 0;
                }

                .g-footer-item-value-item {
                    margin-left: 10px;
                    margin-bottom: 11px;
                    opacity: 0;
                    transform: translateY(-20%) scale(1.2);
                    transition: none;

                    &:last-of-type {
                        margin-bottom: 30px;
                    }
                }

                &.open {
                    height: auto;

                    .g-footer-item-value-item {
                        opacity: 1;
                        transform: translateY(0) scale(1);
                        transition: opacity 0.25s 0.1s ease-out, transform 0.25s 0.1s ease-out;

                        @for $i from 1 through 10 {
                            &:nth-child(#{$i}) {
                                transition-delay: #{$i / 10 - 0.08}s, #{$i / 10 - 0.1}s;
                            }
                        }
                    }
                }
            }

            &.contact,
            &.follow,
            &.subscribe {
                margin-top: 20px;
            }
            &-value {
                &-item {
                    display: block;
                    font-size: 13px;
                    color: rgba(235, 235, 235, 0.5);
                }
            }
        }
        &-submenu {
            &-icon {
                width: 20px;
                height: 20px;
                margin-left: auto;
                margin-right: 6px;
                position: relative;

                span {
                    width: 10px;
                    height: 1px;
                    background: #fff;
                    position: absolute;
                    left: 5px;
                    top: 11px;
                    transition: all 0.4s;
                    &:last-of-type {
                        transform: rotate(-90deg);
                    }
                }

                &.close {
                    span {
                        transform: rotate(45deg);
                        &:last-of-type {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
        &-follow-list {
            display: flex;
        }
        &-follow-item {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        &-subscribe {
            &-title {
                font-size: 12px;
                color: rgba(235, 235, 235, 0.5);
            }

            &-form {
                margin-top: 10px;
                display: flex;
                position: relative;
                input {
                    width: 194px;
                    height: 36px;
                    background: #ebebeb;
                    border: none;
                    border-radius: 0;
                    padding: 4px 10px;
                    box-sizing: border-box;
                }
                &-submit {
                    width: 36px;
                    height: 36px;
                    background: #5CDAD7;
                    margin-left: 10px;
                    font-size: 20px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Roboto;
                    font-weight: 700;
                    cursor: pointer;
                }
                .error {
                    white-space: nowrap;
                    font-size: 12px;
                    transform: scale(0.75);
                    color: #FE480F;
                    position: absolute;
                    transform-origin: 0 center;
                    left: 0;
                    top: 36px;
                }
            }
            &-success {
                font-size: 13px;
                font-weight: 600;
                margin-top: 6px;
                color: #5CDAD7;
            }
            &-desc {
                width: 250px;
                font-size: 9px;
                margin-top: 10px;
                color: rgba(235, 235, 235, 0.5);
            }
        }
        &-copyright {
            margin: 0 30px;
            padding: 20px 0 50px 0;
            height: 43px;
            border-top: solid 1px rgba(235, 235, 235, 0.1);
            display: flex;
            flex-direction: column;
            font-size: 10px;
            line-height: 1.4;
            color: rgba(235, 235, 235, 0.4);

            &-item {
                margin: 2px 0;
            }
            a {
                color: rgba(235, 235, 235, 0.4);
            }
        }
    }
}
</style>
