<template>
    <div class="Page page-not-found">
        <h1 class="poppins page-not-found-title">404 NOT FOUND</h1>
        <router-link class="page-not-found-button" :to="{ path: '/', query: $route.query }">
            {{ $t('goHome') }}
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'pageNotFound',
}
</script>

<style lang="scss">
.page-not-found {
    text-align: center;
    &-title {
        color: rgba(174, 175, 183, 1);
    }
    &-button {
        display: inline-block;
        color: #fff;
        background: rgba(0, 200, 200, 1);
    }

    @media only screen and (min-width: 769px) {
        padding-top: 200px;
        padding-bottom: 330px;

        &-title {
            font-size: 64px;
            font-weight: 300;
            margin-bottom: 24px;
        }
        &-button {
            font-size: 16px;
            font-weight: 400;
            padding: 14px 30px;
            border-radius: 60px;
        }
    }
    @media only screen and (max-width: 768px) {
        padding-top: 200px;
        padding-bottom: 380px;
        &-title {
            font-size: 32px;
            font-weight: 300;
            margin-bottom: 16px;
        }
        &-button {
            padding: 12px 20px;
            font-size: 14px;
            font-weight: 400;
            border-radius: 30px;
        }
    }
}
</style>
