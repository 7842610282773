<template>
    <div class="home-reliable" :class="[currentLang]">
        <div class="home-reliable-container">
            <h2 class="block-title poppins home-reliable-ani home-reliable-title" :class="[currentLang]">{{ i18nPage.title }}</h2>
            <div class="home-reliable-list">
                <div class="home-reliable-ani home-reliable-item" :class="[`item-${index + 1}`, currentLang]" v-for="(item, index) in i18nPage.list" :key="index">
                    <div class="home-reliable-item-top">
                        <div class="text1">
                            <h3 class="poppins name">{{ item.name }}</h3>
                            <div class="industry">{{ item.industry }}</div>
                        </div>
                        <div class="text2" v-for="(job, jobIndex) in item.job" :key="jobIndex">{{ job }}</div>
                    </div>
                    <div class="text3">
                        <div class="item" v-for="(desc, descIndex) in item.desc" :key="descIndex">{{ desc }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('homePage.reliable');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.home-reliable-ani',
            triggerElement: '.home-reliable-container',
        });
    },
};
</script>

<style lang="scss">
.home-reliable {
    @media only screen and (min-width: 769px) {
        &-container {
            width: 960px;
            margin: 0 auto;
            padding: 80px 0;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 40px;
        }
        &-item {
            width: 960px;
            height: 385px;
            border-radius: 20px;
            padding-left: 80px;
            padding-top: 80px;
            box-sizing: border-box;
            &.zh,
            &.tw {
                padding-left: 180px;
                padding-top: 60px;
            }
            &.item-1 {
                margin-bottom: 20px;
                background: url(~@assets/images/home/reliable/pc1.jpg) 0 0 no-repeat;
                background-size: contain;
            }

            &.item-2 {
                background: url(~@assets/images/home/reliable/pc2.jpg) 0 0 no-repeat;
                background-size: contain;
            }

            &-top {
                border-left: 5px solid #00c8c8;
                padding-left: 10px;
                margin-bottom: 30px;
            }
            .text1 {
                margin-bottom: 10px;
            }
            .name {
                font-size: 24px;
                line-height: 28px;
                font-weight: 500;
            }
            .industry {
                font-size: 16px;
                font-weight: 600;
                &.zh,
                &.tw {
                    font-size: 18px;
                }
            }
            .text2 {
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
                color: #00C8C8;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            .text3 {
                padding-left: 15px;
            }
            &.item-1 {
                .text1 {
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;

                    .name {
                        margin-right: 10px;
                    }
                }
                .text3 {
                    width: 530px;
                }
            }

            &.item-3 {
                .name {
                    font-size: 25px;
                }
            }
            &.item-2,
            &.item-3 {
                .text2 {
                    max-width: 300px;
                }
                .text3 {
                    width: 465px;
                }
            }
            &.zh,
            &.tw {
                .home-reliable-item-top {
                    margin-bottom: 20px;
                }
                .text1 {
                    margin-bottom: 6px;
                }
                .name {
                    font-weight: 600;
                }
                .industry {
                    font-size: 18px;
                }
                .text2 {
                    line-height: 21.6px;
                    margin-bottom: 2px;
                    font-weight: 600;
                }
                .text3 {
                    line-height: 25px;
                }
            }
        }
        &.zh,
        &.tw {
            .text3 {
                width: 247px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        &-container {
            padding: 0.6rem 0.35rem;
        }
        &-title {
            font-size: 28px;
            font-weight: 600;
            line-height: 1.1;
            margin-bottom: 0.3rem;
        }
        &-item {
            width: 3.05rem;
            border-radius: 0.12rem;
            margin-bottom: 0.2rem;
            padding-left: 0.23rem;
            padding-top: 0.3rem;
            box-sizing: border-box;

            &:last-of-type {
                margin-bottom: 0;
            }
            &.item-1 {
                height: 3.11rem;
                background: url(~@assets/images/home/reliable/mobile1.jpg) 0 0 no-repeat;
                background-size: contain;
            }
            &.item-2 {
                height: 3.06rem;
                background: url(~@assets/images/home/reliable/mobile2.jpg) 0 0 no-repeat;
                background-size: contain;
            }

            &-top {
                border-left: 3px solid #8ED5FF;
                padding-left: 0.12rem;
                margin-bottom: 0.2rem;
            }
            .name {
                font-size: 20px;
                line-height: 23px;
                font-weight: 600;
                margin-bottom: 3px;
            }
            .industry {
                font-size: 13px;
                line-height: 18px;
                font-weight: 400;
                margin-bottom: 3px;
            }
            .text2 {
                color: #00C8C8;
                font-size: 13px;
                line-height: 17px;
                font-weight: 400;
                width: 1.37rem;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            .text3 {
                font-size: 10px;
                line-height: 1.4;
                font-weight: 400;
                margin-left: 0.15rem;
                width: 1.43rem;

                .item {
                    margin-bottom: 0.05rem;
                }
            }
        }
    }
}
</style>
