<template>
    <div class="Page product">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'product',
};
</script>

<style lang="scss">
.Page.product {
    background: #fff;
}
</style>
